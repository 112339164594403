import CookieConsent from "react-cookie-consent";
import React from "react";
import {useTranslation} from "react-i18next";
import {getCompanyDomain} from "./utils/util";

export const CookieBanner = () => {
    
    const {t} = useTranslation();
    const companyDomain = getCompanyDomain();
    return (
        <CookieConsent
            ariaAcceptLabel="cookie-button"
            buttonId="cookie-button"
            location="bottom" buttonText={t("global.cookie.confirm")}
            cookieName="cookie_notice_accepted"
            style={{background: "rgb(45, 190, 205)", color: "white", alignItems: "center", minHeight: "75px", zIndex: 9999}}
            declineButtonStyle={{fontSize: "13px", width: "80px"}} expires={150}
        >
            <div className="columns">
                <div className="column is-full" style={{textAlign: "center"}}>
                <span>
                    {t("global.cookie.banner")}{" "}
                    <a href={t("global.footer.privacystatement.link", {companyDomain})} style={{textDecoration: "underline", color: "white",}}>
                        {t("global.cookie.policy")}
                    </a>
                </span>
                </div>
            </div>
        </CookieConsent>)
}
