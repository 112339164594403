import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CPModule } from "designsystem/src/components/CPModule/CPModule";
import { contextPath } from "../utils/apiUrl";
import { getCurrentCompanyName } from "../utils/util";
import { Heading } from "designsystem/src/components/Heading/Heading";
import { Paragraph } from "designsystem/src/components/Paragraph/Paragraph";
import { CardGroupHeader } from "designsystem/src/components/CardGroupHeader/CardGroupHeader";
import {
  New,
  Old,
  Redesign,
} from "designsystem/src/components/Redesign/Redesign";
import { client } from "../utils/fetch-client";
import { UserInfo } from "../interfaces/types";
import { Spacing } from "designsystem/src/components/Spacing";
import { Button } from "designsystem/src/components/Button/Button";

export const signInFunction = () => {
  return window.location.assign(`${contextPath}/oauth2/authorization/oidc`);
};

export const LoginPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const login = async () => {
    const { status } = await client.query<UserInfo>({
      method: "OPTIONS",
      redirect: "manual",
      endpoint: `${contextPath}/fidoCredentials`,
      responseType: "json",
    });

    if (status == 200) {
      window.location.assign(`${contextPath}/fidoCredentials`);
    } else {
      signInFunction();
    }
  };

  const companyName = getCurrentCompanyName();

  return (
    <div className="portal_loginpage">
      <Redesign>
        <Old>
          <div className={"portal_loginpage_heading"}>
            <Heading text={"Credential Self-Service Portal"} type={"h1"} />
          </div>
          <div className={"portal_loginpage_text"}>
            <Paragraph
              text={t("portal.landingPage.intro", { companyName: companyName })}
            />
          </div>
        </Old>
      </Redesign>
      <div>
        <Redesign>
          <New>
            <Spacing flexDirection="column" gap="40">
              <CardGroupHeader
                title={t("portal.landingPage.redesigned.headline")}
                description={t("portal.landingPage.redesigned.description")}
                status="info"
                icon="user-01"
              />
              <Spacing flexDirection="row" gap="16" alignItems="center">
                <Button
                  mode="yellow"
                  onClick={login}
                  id="signIn"
                  label={t("portal.landingPage.redesigned.buttons.signInLabel")}
                />
                <Button
                  mode="link"
                  onClick={() => window.location.assign("/cannotlogin")}
                  id="cant-login"
                  label={t(
                    "portal.landingPage.redesigned.buttons.anotherSignInLabel",
                  )}
                />
              </Spacing>
            </Spacing>
          </New>
        </Redesign>
        <Redesign>
          <Old>
            <CPModule
              subtitleI18n={"portal.landingPage.content.text"}
              title={t("portal.landingPage.content.headline")}
              buttons={{
                signIn: {
                  label: t("portal.landingPage.content.buttons.signInLabel"),
                  id: "signIn",
                  onClick: login,
                },
                cantLogin: {
                  label: t(
                    "portal.landingPage.content.buttons.cantLoginLabel",
                    {
                      companyName: companyName,
                    },
                  ),
                  id: "cant-login",
                  onClick: () => window.location.assign("/cannotlogin"),
                },
              }}
            />
          </Old>
        </Redesign>
      </div>
    </div>
  );
};
