import React from 'react';
import './radioButton.scss';
import {useTranslation} from "react-i18next";
import {RadioButton_Props} from "../../../../shared/interfaces";

/**
 * Primary UI component for user interaction
 */
export const StandardRadioButton : React.FC<RadioButton_Props> = (props) => {
    
    const {t} = useTranslation();
    
    return (
        <div className={["radiobutton-wrapper", props.className, props.hide ? "hide" : "", props.recommended ? "highlight" : "no-highlight"].join(" ")}>
            {props.recommended &&
            <div className={"recommended"} onClick={props.onClick}>
                {t("button.recommended")}
            </div>
            }
            <div className="radiobutton-input-row" onClick={props.onClick}>
                <div className="input">
                    <input id={props.id} aria-label={props.id} type="radio" readOnly
                           checked={props.selected}/>
                </div>
                <div>
                    <label htmlFor={props.id} aria-label={props.id}>
                        {props.label}
                    </label>
                </div>
            </div>
        </div>
    )
};
