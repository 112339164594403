import React from 'react';
import './list.scss';
import {ListProps} from "../../../../shared/interfaces";
import {Paragraph} from "../Paragraph/Paragraph";

/**
 * Primary UI component for user interaction
 */
export const List : React.FC<ListProps> = (props) => {


  const getListContent = () => {
    return props.subitems.map((child, index) => {
      let idAndKey = props.id ? props.id + "-" + index.toString() : "list" + index;
      return <>
        <li id={idAndKey} key={idAndKey}>{child}</li>
        {
            props.useBreaksBetweenItems && <br/>
        }
      </>
    })
  }

  return (
      <div className={"listWrapper"}>
        {props.intro && <Paragraph text={props.intro}/>}
        {props.type === "unordered" ? 
            <ul aria-label={props.id} id={props.id} className={props.className}>
              {getListContent()}
            </ul>
            :
            <ol aria-label={props.id} id={props.id} className={props.className}>
              {getListContent()}
            </ol>
        }
      </div>
  );
}