import React, {FunctionComponent, useEffect, useState} from "react";
import {ErrorResponse, UserInfo} from "../interfaces/types";
import {Spacing} from "designsystem/src/components/Spacing";
import {CardGroupHeader} from "designsystem/src/components/CardGroupHeader";
import {CardGroup, CardGroupItem} from "designsystem/src/components/CardGroup";
import {Badge} from "designsystem/src/components/Badge";
import {Toggle} from "designsystem/src/components/Toggle/Toggle";
import {Modal} from "designsystem/src/components/Modal/Modal";
import {useTranslation} from "react-i18next";
import {useGlobalState} from "../GlobalState";
import {httpGet, httpPost} from "../utils/fetch-client";
import {apiPath} from "../utils/apiUrl";
import {useCookies} from "react-cookie";
import {signInFunction} from "./LoginPage";


type SIGN_INS = "AZURE_DEVICE_CODE_FLOW"| "DEVICE_ENROLLMENT" | "VPN_PRE_LOGIN";

const AZURE_DEVICE_CODE_FLOW: SIGN_INS = "AZURE_DEVICE_CODE_FLOW";

export interface LessSecureSignIns {
  azureDeviceCodeFlowActive:  "ACTIVE" | "DEACTIVATE" | "DISABLED";
}

export function isLessSecureSignIn(lessSecureSignIn: LessSecureSignIns | undefined) : boolean | "DISABLED" {

  if (lessSecureSignIn) {
    // Check any is active or deactivated
    if (lessSecureSignIn.azureDeviceCodeFlowActive !== "DISABLED") {
      return lessSecureSignIn.azureDeviceCodeFlowActive === "ACTIVE";
    }
  }
  return "DISABLED";
}

export const useLessSecureSignInsDetails = () => {
  const [, setIsLoading] = useGlobalState("isLoading");
  const [cookies] = useCookies();

  const state = useState<LessSecureSignIns|undefined>(undefined);
  const [, setLessSecureSignIns] = state;

  const fetchLessSecureSignInsDetails = async () : Promise<LessSecureSignIns|false> => {
    try {
      setIsLoading({ loadingType: "standard" });

      const {status, error, payload} = await httpGet<LessSecureSignIns>(
          `${apiPath}/user/less-secure-sign-ins`,
          cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        console.error("fetch less secure sign-ins failed.", error);
        return false;
      }

      if (status == 200 && payload) {
        return payload;
      }

    } finally {
      setIsLoading({ loadingType: null });
    }

    return false;
  }

  useEffect(() => {
    fetchLessSecureSignInsDetails().then(lessSecureSignIns => {
      if (lessSecureSignIns) {
        setLessSecureSignIns(lessSecureSignIns);
      }
    });
  }, []);

  return state;
};

export const LessSecureSignInPage: FunctionComponent<{ user: UserInfo }> = ({user}) => {

  const {t} = useTranslation(["de", "en"]);
  const [modal, setModal] = useGlobalState("modal");
  const [, setGlobalError] = useGlobalState("error");
  const [cookies] = useCookies();
  const [, setIsLoading] = useGlobalState("isLoading");

  const [lessSecureSignIns, setLessSecureSignIns] = useLessSecureSignInsDetails();

  const fetchToken = async () : Promise<string> => {
    try {
      setIsLoading({loadingType: "standard"});

      const {status, error, payload : token} = await httpGet<{ token:string }>(
          `${apiPath}/user/token?subject=ActivateAzureDeviceCodeFlowAction`,
          cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      if (status == 200 && token) {
        return token.token;
      }

    } finally {
      setIsLoading({ loadingType: null });
    }

    return "";
  };

  const fetchDeactivateLessSecureSignIn = async (deactivate: SIGN_INS): Promise<LessSecureSignIns| false> => {
    try {
      setIsLoading({ loadingType: "standard" });

      const {status, error, payload} = await httpPost<LessSecureSignIns>(
          `${apiPath}/user/less-secure-sign-ins`,
          { deactivate },
          cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
            (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return false;
      }

      if (status == 200 && payload) {
        return payload;
      }

    } finally {
      setIsLoading({ loadingType: null });
    }
    return false;
  }

  const toggleAzureDeviceCodeFlow = async () => {

    if (lessSecureSignIns?.azureDeviceCodeFlowActive === "ACTIVE") {

      const lessSecureSignIns = await fetchDeactivateLessSecureSignIn(AZURE_DEVICE_CODE_FLOW);

      if (lessSecureSignIns) {
        setLessSecureSignIns(lessSecureSignIns);
      }
    } else {

      const token = await fetchToken();

      window.location.assign("/activateAzureDeviceCodeFlow?factor=FIDO2_USER_VERIFICATION&upnToAuthenticate=" + decodeURIComponent(user.preferredUsername) + "&ptoken=" + token);
    }
  }

  return (
      <div className={"portal"}>
        <div className="portal-main-wrapper">
          <div className="portal-main-content">
            <div className="portal-column">
              <div className="portal-column-item">
                <Spacing flexDirection="column" gap="24">
                  <CardGroupHeader
                      status={isLessSecureSignIn(lessSecureSignIns) === true
                          ? "warning"
                          : "success"
                      }
                      description={
                        isLessSecureSignIn(lessSecureSignIns) === true
                            ? t("portal.securityCheckup.warning")
                            : t("portal.securityCheckup.success")
                      }
                      title={t("portal.securityCheckup.lessSecureSignIns.headline")}
                  />
                  <CardGroup>
                    <CardGroupItem
                        id="activateAzureDeviceCodeFlow"
                        label={t(
                            "portal.securityCheckup.lessSecureSignIns.azureDeviceCodeFlow.headline",
                        )}
                        disabled={!lessSecureSignIns || lessSecureSignIns.azureDeviceCodeFlowActive === "DISABLED"}
                        icon="shield-plus"
                        badge={
                          <Badge
                              label={
                                lessSecureSignIns?.azureDeviceCodeFlowActive === "ACTIVE" ? "On" : "Off"
                              }
                              status={
                                lessSecureSignIns?.azureDeviceCodeFlowActive === "ACTIVE" ? "warning" : "success"
                              }
                          />
                        }>
                      <Spacing flexWrap="nowrap">
                                <span>
                                  {lessSecureSignIns?.azureDeviceCodeFlowActive === "ACTIVE"
                                      ? t("portal.securityCheckup.lessSecureSignIns.azureDeviceCodeFlow.warning")
                                      : t("portal.securityCheckup.lessSecureSignIns.azureDeviceCodeFlow.success")}
                                </span>
                        <Toggle
                            style={{flexGrow: 0}}
                            id="activateAzureDeviceCodeFlow-toggle"
                            toggleState={lessSecureSignIns?.azureDeviceCodeFlowActive === "ACTIVE"}
                            setToggleState={toggleAzureDeviceCodeFlow}
                            disabled={!lessSecureSignIns || lessSecureSignIns.azureDeviceCodeFlowActive === "DISABLED"}
                        />
                      </Spacing>
                    </CardGroupItem>
                  </CardGroup>
            </Spacing>
              </div>
            </div>
          </div>
        </div>

        <div className="mainWrapper">
          {modal && (
              <Modal
                  type={modal.type}
                  onSubmit={modal.onSubmit}
                  title={modal.title}
                  children={modal.children}
                  submitButtonLabel={modal.submitButtonLabel}
                  resetButtonLabel={modal.resetButtonLabel}
                  onClose={modal.onClose}
                  backgroundLocked={true}
                  submitButtonId={modal.submitButtonId}
                  cancelButtonId={modal.cancelButtonId}
                  onMagicBtn={modal.onMagicBtn}
                  magicButtonId={modal.magicButtonId}
                  magicButtonLabel={modal.magicButtonLabel}
                  submitButtonDisabled={modal.submitButtonDisabled}
              />
          )}
        </div>
      </div>
  );
}


