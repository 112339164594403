import React from 'react';
import './toggle.scss';
import {ToggleProps} from "../../../../shared/interfaces";

export const Toggle : React.FC<ToggleProps> = (props) => {

  const handleState = () => {
    props.toggleState ? props.setToggleState(false) : props.setToggleState(true)
  }

  return (
      <div className="toggle-wrapper" style={props?.style}>
        <div className={["toggle checkcross", props.disabled ? "disabled" : "", props.className].join(" ")}>
          <input checked={props.toggleState} id={props.id} aria-label={props.id} className={"toggle-input"} type="checkbox" onClick={handleState}  disabled={props.disabled}/>
          <label className="toggle-item" htmlFor={props.id} aria-label={props.id}>
            <div className={"check"}></div>
          </label>
        </div>
      </div>
  )
};
