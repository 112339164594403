import React from 'react';
import {I18NParagraphProps} from "../../../../shared/interfaces";
import "../Paragraph/paragraph.scss"
import {Trans} from "react-i18next";

/**
 * Primary UI component for user interaction
 */
export const I18NParagraph : React.FC<I18NParagraphProps> = ({id, color, values, className, styles, title,i18n,options, components, children}) => {
  
    return (
        <p aria-label={id} id={id} className={[color, className].join(' ')} style={styles} title={title}>
            <Trans i18nKey={i18n} values={values} tOptions={{...options}} components={components}>
              {children}
            </Trans>
        </p>
    );
};
