import React from 'react';
import "./modal.scss"
import {ModalProps} from "../../../../shared/interfaces";
import {Button} from "../Button/Button";
import {IconButton} from "../IconButton/IconButton";
import {Icon} from "../Icon/Icon";
import {Heading} from "../Heading/Heading";
import {useTranslation} from "react-i18next";

/**
 * Primary UI component for user interaction
 */
export const Modal: React.FC<ModalProps> = (
    {
        title,
        buttons,
        type,
        onSubmit,
        onClose,
        className,
        backgroundLocked,
        children,
        submitButtonId,
        cancelButtonId,
        successSubmitButtonLabel,
        resetButtonLabel,
        submitButtonLabel,
        noButtons,
        enableErrorSubmitButton,
        onMagicBtn,
        magicButtonLabel,
        magicButtonId,
        submitButtonDisabled
    }) => {

    const {t} = useTranslation();

    if (!type) {
        type = "error"
    }

    const submitModalOnClick = () => {
        if (onSubmit) {
            onSubmit();
        }
    }

    const resetModalOnClick = () => {
        if (onClose) {
            onClose();
        }
    }

    let buttonsToRender = (<></>);

    if (!noButtons) {
        switch (type) {
            case "success":
                buttonsToRender = (<>
                    {onSubmit &&
                        <Button label={successSubmitButtonLabel ? successSubmitButtonLabel : t('button.next')}
                                mode="yellow"

                                id={submitButtonId}
                                disabled={submitButtonDisabled === true}
                                onClick={submitModalOnClick}/>}
                </>);
                break;
            case "error":
                if (enableErrorSubmitButton) {
                    buttonsToRender = (<>
                        {onSubmit &&
                            <Button label={submitButtonLabel ? submitButtonLabel : t('button.yes')} mode="yellow"
                                    id={submitButtonId}
                                    disabled={submitButtonDisabled === true}
                                    onClick={submitModalOnClick}/>}
                    </>);
                }
                break;
            case "warning":
            case "info":
                buttonsToRender = (<>
                    {onClose &&
                        <Button label={resetButtonLabel ? resetButtonLabel : t('button.cancel')} mode="white"
                                border={true}
                                id={cancelButtonId}
                                onClick={resetModalOnClick}/>}
                    {onMagicBtn &&
                        <Button label={magicButtonLabel ? magicButtonLabel : t('button.change')} mode="yellow"
                                border={true}
                                id={magicButtonId}
                                onClick={onMagicBtn}/>}
                    {onSubmit &&
                        <Button label={submitButtonLabel ? submitButtonLabel : t('button.yes')} mode={onMagicBtn ? "white" : "yellow"}
                                border={true}
                                id={submitButtonId}
                                disabled={submitButtonDisabled === true}
                                onClick={submitModalOnClick}/>}

                </>);
                break;
        }
    }

    return (
        <div className={backgroundLocked ? "locked" : ""}>
            <div className={["modal-wrapper", className].join(" ")}>
                <div className="modal">
                    <div className="modal-header">
                        <div className={"modal-icon-heading-wrapper"}>
                            <Icon className="modal-icon" icon={type ? type : "info"} alt={" "}/>
                            <Heading type={"h4"} text={title ? title : ""} className={"modal-header-title"}/>
                        </div>
                        <div className={"modal-header-button"}>
                            {type !== "success" && onClose &&
                                <IconButton icon="cross_purple" onClick={resetModalOnClick}
                                            className={"modal-close-button"} alt={t("button.cancel")}/>
                            }
                        </div>
                    </div>
                    <div className="modal-content">
                        {
                            typeof children === "object" ? <>{children}</> :
                                <div dangerouslySetInnerHTML={{__html: children}}/>
                        }
                    </div>
                    <div className="modal-buttons">
                        {buttons ? buttons : buttonsToRender}
                    </div>
                </div>
            </div>
        </div>
    );
};
